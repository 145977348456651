import { isMobile } from './utils';

async function load() {
  if (isMobile()) {
    const { AppBase } = await import('./app-base');

    (new AppBase()).init();
  } else {
    const { AppExtended }  = await import('./app-extended');

    (new AppExtended()).init();
  }
}

setTimeout(() => load());